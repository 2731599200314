import React, { Suspense, lazy } from 'react';
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';
import {
	NotFoundTrigger,
	PageError,
	PageNotFound,
	ScrollToTop,
} from '@automation-analytics/component-library';

import GlobalWrapper from 'components/layout/GlobalWrapper';
import RequireAuth from 'navigation/RequireAuth';
import ErrorBoundary from 'navigation/ErrorBoundary';
import { Authorize, Loading, Logout } from 'pages/utilities';
import AuthTrigger from 'navigation/AuthTrigger';
import Unauthorized from 'pages/errors/Unauthorized';
import { paths } from 'enums';

const HomePage = lazy(() => import('pages/Home'));
const SelectAssessmentPage = lazy(() => import('pages/SelectAssessment'));
const AssessmentPage = lazy(() => import('pages/Assessment'));
const HistoricalAssessments = lazy(() => import('pages/HistoricalAssessments'));
const Report = lazy(() => import('pages/Report'));
const Versions = lazy(() => import('pages/utilities/Versions'));

const router = createBrowserRouter([
	{
		path: paths.root,
		element: (
			<GlobalWrapper>
				<ScrollToTop />
				<Suspense fallback={<Loading />}>
					<Outlet />
				</Suspense>
			</GlobalWrapper>
		),
		errorElement: <ErrorBoundary redirectTo={paths.error} />,
		children: [
			// public
			// unauthed users should be automatically redirected to SSO
			{
				index: true,
				element: <AuthTrigger redirectTo={paths.landing} />,
			},

			// used to programmatically login user
			{
				path: paths.authorize,
				element: <Authorize redirectTo={paths.landing} />,
			},

			// used to programmatically logout user
			{
				path: paths.logout,
				element: <Logout redirectTo={paths.unauthorized} />,
			},

			{
				path: paths.version,
				element: <Versions />,
			},

			// private
			{
				element: <RequireAuth redirectTo={paths.unauthorized} />,
				children: [
					{
						path: paths.landing,
						element: <HomePage />,
					},
					{
						path: paths.startAssessment,
						element: <SelectAssessmentPage />,
					},
					{
						path: `${paths.assessment}/:id`,
						element: <AssessmentPage />,
					},
					{
						path: `${paths.report}/:id`,
						element: <Report />,
					},
					{
						path: paths.trendData,
						element: <HistoricalAssessments />,
					},
				],
			},

			// errors and fallback
			{
				path: paths.wildcard,
				element: <NotFoundTrigger />,
			},
			{
				path: paths.notFound,
				element: <PageNotFound redirectTo={paths.root} />,
			},
			{
				path: paths.error,
				element: <PageError redirectTo={paths.root} />,
			},
			{
				path: paths.unauthorized,
				element: <Unauthorized redirectTo={paths.landing} />,
			},
		],
	},
]);

export default function Router() {
	return <RouterProvider router={router} />;
}
