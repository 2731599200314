import { Agent } from '@newrelic/browser-agent/loaders/agent';
import { Ajax } from '@newrelic/browser-agent/features/ajax';
import { JSErrors } from '@newrelic/browser-agent/features/jserrors';
import { PageAction } from '@newrelic/browser-agent/features/page_action';
import { SessionTrace } from '@newrelic/browser-agent/features/session_trace';
import { Spa } from '@newrelic/browser-agent/features/spa';
import { Logging } from '@newrelic/browser-agent/features/logging';
import { PageViewEvent } from '@newrelic/browser-agent/features/page_view_event';
import { PageViewTiming } from '@newrelic/browser-agent/features/page_view_timing';
import { Metrics } from '@newrelic/browser-agent/features/metrics';

interface ContextObject extends Record<string, unknown> {}

interface Callback {
	(): void;
}

interface ErrorHandler {
	(err: unknown): boolean;
}

interface GetContextCallback<T extends ContextObject = ContextObject> {
	(contextObject: T): void;
}

interface Wrapper {
	(): void;
}

interface Info {
	agent: string;
	applicationID: string;
	beacon: string;
	errorBeacon: string;
	jsAttributes: Record<string, unknown>;
	licenseKey: string;
	sa: number;
}

interface EventObject {
	name: string;
	start: number;
	end?: number | undefined;
	origin?: string | undefined;
	type?: string | undefined;
}

// SPA tracking API
interface BrowserInteraction {
	actionText(value: string): BrowserInteraction;
	createTracer(name: string, callback?: Callback): Wrapper;
	end(): BrowserInteraction;
	getContext<T extends ContextObject = ContextObject>(
		callback: GetContextCallback<T>
	): BrowserInteraction;
	ignore(): BrowserInteraction;
	onEnd<T extends ContextObject = ContextObject>(
		callback: GetContextCallback<T>
	): BrowserInteraction;
	save(): BrowserInteraction;
	setAttribute(key: string, value: unknown): BrowserInteraction;
	setName(name: string, trigger?: string): BrowserInteraction;
}

// callables
interface NewRelicGlobalMethods {
	addRelease(releaseName: string, releaseId: string): void;
	addPageAction(name: string, attributes?: Record<string, unknown>): void;
	addToTrace(eventObject: EventObject): void;
	finished(timestamp?: number): void;
	noticeError(
		error: Error | string,
		customAttributes?: Record<string, unknown>
	): void;
	setUserId(value: string | null): void;
	setCustomAttribute(name: string, value: unknown, persist?: boolean): void;
	setErrorHandler(filterCallback: ErrorHandler): void;
	setPageViewName(name: string, host?: string): void;
	interaction(): BrowserInteraction;
	setCurrentRouteName(name: string | null): void;
	wrapLogger(
		logger: Console,
		method: 'error' | 'warn',
		options?: NonNullable<{
			customAttributes?: Record<string, unknown>;
			level?: 'debug' | 'error' | 'info' | 'trace' | 'warn';
		}>
	): void;
}

// non-callables
interface NewRelicGlobal extends NewRelicGlobalMethods {
	info: Info;
}

// newrelic attaches a global interface to the window object if the function `instantiate` runs
declare global {
	interface Window {
		newrelic: NewRelicGlobal;
	}
}

let agent: NewRelicGlobal | null = null;

const createNewRelicAgent = ({
	applicationID,
	agentID,
	licenseKey,
}: {
	applicationID: string;
	agentID: string;
	licenseKey: string;
}): Agent => {
	// the BrowserAgent class enables all newrelic features by default
	// We moved to using the more granular Agent class to enable only the features we need
	const browserAgent = new Agent({
		init: {
			distributed_tracing: { enabled: true },
			privacy: { cookies_enabled: true },
			ajax: {
				deny_list: ['bam.nr-data.net'],
				autoStart: true,
			},
			jserrors: {
				autoStart: true,
			},
			metrics: {
				autoStart: true,
			},
			page_action: {
				autoStart: true,
			},
			page_view_event: {
				autoStart: true,
			},
			page_view_timing: {
				autoStart: true,
			},
			session_replay: {
				autoStart: true,
			},
			session_trace: {
				autoStart: true,
			},
			spa: {
				autoStart: true,
			},
			logging: {
				enabled: true,
				autoStart: true,
			},
		},
		info: {
			beacon: 'bam.nr-data.net',
			errorBeacon: 'bam.nr-data.net',
			licenseKey,
			applicationID,
			sa: 1,
		},
		loader_config: {
			accountID: '3619142',
			trustKey: '690234',
			agentID,
			licenseKey,
			applicationID,
		},
		features: [
			Ajax,
			JSErrors,
			PageAction,
			PageViewEvent,
			PageViewTiming,
			Metrics,
			SessionTrace,
			Spa,
			Logging,
		],
	});

	return browserAgent;
};

const instantiate = (): void => {
	const nrConfig = {
		applicationID: process.env.REACT_APP_NR_APP_ID,
		agentID: process.env.REACT_APP_NR_AGENT_ID,
		licenseKey: process.env.REACT_APP_NR_LICENSE_KEY,
	};

	if (!Object.values(nrConfig).every(Boolean)) {
		console.warn('Skipping New Relic instantiation');
		return;
	}

	try {
		createNewRelicAgent(nrConfig as Record<keyof typeof nrConfig, string>);
		agent = window.newrelic;

		// forward console.error and console.warn to newrelic
		agent.wrapLogger(console, 'error', { level: 'error' });
		agent.wrapLogger(console, 'warn', { level: 'warn' });
	} catch (e) {
		console.error('There was an error instantiating New Relic', e);
	}
};

const setUserId = (id: string | null): void => {
	if (agent === null) return;
	agent.setUserId(id);
};

instantiate();

export default setUserId;
